import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import Lang from '../../../../../Lang'
import { DateInput2, TimePicker } from '@blueprintjs/datetime2'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { Button, ButtonGroup, InputGroup, Switch, Menu, MenuItem, NumericInput, Checkbox } from '@blueprintjs/core'
import { start } from 'repl'
import { dateToSecondsToday, secondsTodayToDate } from '../../../../../Util'
import { AppToaster } from '../../../../../Components/App'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'

// ===================================================================

const conditionTypes = [
	{
		type: 'weekdays',
		label: Lang.get('Weekdays'),
		icon: 'fa-calendar-clock',
		incompatibleWith: ['weekdays'],
		defaultValue: []
	},
	{
		type: 'nightlight',
		label: Lang.get('Nightlight'),
		icon: 'fa-moon',
		incompatibleWith: ['nightlight', 'daylight'],
		defaultValue: { sunsetOffset: 0, sunriseOffset: 0 }
	},
	{
		type: 'daylight',
		label: Lang.get('Daylight'),
		icon: 'fa-sun',
		incompatibleWith: ['nightlight', 'daylight'],
		defaultValue: { sunsetOffset: 0, sunriseOffset: 0 }
	},
	{
		type: 'date',
		label: Lang.get('Date'),
		icon: 'fa-calendar',
		incompatibleWith: ['date'],
		defaultValue: DateTime.now().toFormat('yyyy-LL-dd')
	}
]

const MIN_PERIOD_DURATION = 900

// ===================================================================

export default function ConditionBlock({ lightingProfileId, blockData, blockAction, sunriseSunsetTimes }) {
	const [is1212Mode, setIs1212Mode] = useState(false)

	async function addCondition(conditionType) {
		const condition = conditionTypes.find((c) => c.type == conditionType)
		if (!condition) {
			return
		}
		if (condition.incompatibleWith.some((c) => blockData.conditions[c])) {
			;(await AppToaster).show({
				message: Lang.get('This condition is incompatible with existing conditions'),
				intent: 'warning'
			})
			return
		}

		blockAction('set-value', { conditions: { ...blockData.conditions, [condition.type]: condition.defaultValue } })
	}

	function removeCondition(conditionType) {
		const newConditions = { ...blockData.conditions }
		delete newConditions[conditionType]
		blockAction('set-value', { conditions: newConditions })
	}

	const hasConditions = Object.keys(blockData.conditions).length > 0

	const [editState, setEditState] = useState({
		periods: blockData.brightnessLevels
	})

	useEffect(() => {
		setEditState({
			periods: blockData.brightnessLevels
		})
	}, [lightingProfileId])

	return (
		<div className="ConditionBlock">
			<div className="header">
				<div className="padded">
					<Switch
						label={Lang.get('Active')}
						checked={blockData.active}
						onChange={(e) => blockAction('set-value', { active: e.target.checked })}
					/>
					<InputGroup
						small
						placeholder={Lang.get('Note')}
						value={blockData.comment}
						onChange={(e) => blockAction('set-value', { comment: e.target.value })}
					/>
					<div>
						<ButtonGroup>
							<Tooltip2 content={Lang.get('Duplicate')}>
								<Button small minimal icon={<i className="fa-light fa-clone"></i>} onClick={() => blockAction('duplicate')} />
							</Tooltip2>
							<Button small minimal icon={<i className="fa-solid fa-caret-up"></i>} onClick={() => blockAction('move-up')} />
							<Button small minimal icon={<i className="fa-solid fa-caret-down"></i>} onClick={() => blockAction('move-down')} />
						</ButtonGroup>
						<Button
							small
							minimal
							intent="danger"
							icon={<i className="fa-solid fa-xmark"></i>}
							onClick={() => blockAction('delete')}
						/>
					</div>
				</div>
			</div>

			<div className="padded">
				<div className="legend-block">
					<div>
						<Checkbox checked={is1212Mode} label="12-12 mode" onChange={(e) => setIs1212Mode(e.target.checked)} />
					</div>
					<div className="DayBrightnessEditorLegend">
						<div className="daylight">{Lang.get('Daylight')}</div>
						<div className="idle">{Lang.get('No Motion')}</div>
						<div className="active">{Lang.get('Motion')}</div>
					</div>
				</div>

				<DayBrightnessEditor value={editState.periods} onChange={(prds) => setEditState((cs) => ({ ...cs, periods: prds }))} />
			</div>
			<div className="conditions">
				<h4>
					{Lang.get('Conditions')}
					<Popover2
						content={
							<Menu>
								{conditionTypes.map((c, i) => (
									<MenuItem
										key={`cbct-${c.label}${i}`}
										icon={<i className={`fa-fw fa-solid ${c.icon}`} />}
										onClick={() => addCondition(c.type)}
										text={c.label}
									/>
								))}
							</Menu>
						}
						placement="bottom">
						<Button small minimal intent="success" icon={<i className="fa-solid fa-plus"></i>} />
					</Popover2>
				</h4>

				{hasConditions &&
					Object.keys(blockData.conditions).map((conditionType) => {
						const conditionData = blockData.conditions[conditionType]
						return (
							<Condition
								conditionType={conditionType}
								data={conditionData}
								parentConditions={blockData.conditions}
								key={`cb-${conditionType}`}
								onRemove={() => {
									removeCondition(conditionType)
								}}
								onValueChange={(newValue) => {
									blockAction('set-value', { conditions: { ...blockData.conditions, [conditionType]: newValue } })
								}}
							/>
						)
					})}

				{!hasConditions && <div className="noConditions">{Lang.get('No conditions')}</div>}
			</div>
		</div>
	)
}

function scaleTo100(value, minValue, maxValue) {
	return ((value - minValue) / (maxValue - minValue)) * 100
}

// ===================================================================
function DayBrightnessEditor({ value, onChange }) {
	function onLayoutChange(sizes) {
		const newValue = structuredClone(value)

		for (const [idx, size] of sizes.entries()) {
			if (idx === 0) {
				newValue[idx].start = 0
			} else {
				newValue[idx].start = newValue[idx - 1].duration + newValue[idx - 1].start
			}

			newValue[idx].duration = Math.round((size / 100) * 86400)
		}

		console.log(newValue)

		onChange(newValue)
	}

	function deletePeriod(idx) {
		const newValues = structuredClone(value)
		if (newValues.length == 0) return

		delete newValues[idx]
		onChange(newValues.filter((v) => v))
	}

	function setBrightness(key, val, idx) {
		const newValues = structuredClone(value)
		const curBlock = newValues[idx]

		switch (key) {
			// idleBrightness <= activeBrightness
			case 'idleBrightness': {
				if (val > curBlock.activeBrightness) {
					curBlock.activeBrightness = val
				}
				curBlock.idleBrightness = val

				break
			}

			// activeBrightness >= idleBrightness
			case 'activeBrightness': {
				if (val < curBlock.idleBrightness) {
					curBlock.idleBrightness = val
				}
				curBlock.activeBrightness = val

				break
			}
		}

		onChange(newValues)
	}

	console.log(value)

	return (
		<div className="day-brightness-editor-wrapper">
			<PanelGroup direction="horizontal" onLayout={onLayoutChange} style={{ overflow: undefined }}>
				{value.map((period, idx) => {
					const isFirst = idx == 0
					const isLast = idx == value.length - 1

					const ret = []

					if (!isFirst) {
						ret.push(
							<PanelResizeHandle key={`resize-handle${idx}`}>
								<div className="handle"></div>
							</PanelResizeHandle>
						)
					}

					ret.push(
						<Panel defaultSize={scaleTo100(period.duration, 0, 100)} key={`dbewpanel-${idx}`} style={{ overflow: undefined }}>
							<PeriodBrightnessSliderStack period={period} onChange={(key, value) => setBrightness(key, value, idx)} />
						</Panel>
					)

					return ret.map((r) => r)
				})}
			</PanelGroup>
		</div>
	)
}

function PeriodBrightnessSliderStack({ period, onChange }) {
	const activeSliderRef = useRef(null)
	const idleSliderRef = useRef(null)

	useEffect(() => {
		activeSliderRef.current?.resize(period.activeBrightness * 100)
		idleSliderRef.current?.resize(period.idleBrightness * 100)
	}, [period.activeBrightness, period.idleBrightness])

	return (
		<div className="period-brightness-slider-stack-wrapper">
			<div className="period-block">
				<PanelGroup direction="vertical" className="panel-group">
					<Panel className="dead-panel" />
					<PanelResizeHandle />
					<Panel
						defaultSize={period.activeBrightness * 100}
						onResize={(size) => onChange('activeBrightness', size / 100)}
						className="brightness-slider motion"
						ref={activeSliderRef}
					/>
				</PanelGroup>
				<PanelGroup direction="vertical" className="panel-group">
					<Panel className="dead-panel" />
					<PanelResizeHandle />
					<Panel
						defaultSize={period.idleBrightness * 100}
						onResize={(size) => onChange('idleBrightness', size / 100)}
						className="brightness-slider idle"
						ref={idleSliderRef}
					/>
				</PanelGroup>
			</div>
			<div className="actions">
				<ButtonGroup>
					<Button small minimal intent="danger" icon={<i className="fa-solid fa-minus"></i>}></Button>
					<Button small minimal intent="success" icon={<i className="fa-solid fa-plus"></i>}></Button>
				</ButtonGroup>
			</div>
		</div>
	)
}

function BrightnessTable({}) {
	return (
		<div className="brightness-table-wrapper">
			<table className="bp5-html-table bp5-compact bp5-html-table-bordered">
				<thead>
					<tr>
						<th></th>
						<th>{Lang.get('Start')}</th>
						<th>{Lang.get('No Motion')} %</th>
						<th>{Lang.get('Motion')} %</th>
						<th className="row-action-cell"></th>
					</tr>
				</thead>
				<tbody>
					{displayPeriods.map((period, i) => {
						const prevFake = displayPeriods[i - 1]?.fake

						const isDayRow = blockData.conditions.nightlight && period.fake
						const isNightRow = blockData.conditions.daylight && period.fake

						console.log(period)

						return (
							<BrightnessTableRow
								key={`btr-${i}`}
								is1212Mode={is1212Mode}
								periods={displayPeriods}
								period={period}
								onStartChange={(newSeconds) => {
									console.log({ newSeconds })
									setPeriodValue(period.sourceId ?? i, 'start', newSeconds)
								}}
								onIdleBrightnessChange={(valueNumber) =>
									!(valueNumber < 0 || valueNumber > 100) &&
									!isNaN(valueNumber) &&
									setPeriodValue(period.sourceId ?? i, 'idleBrightness', valueNumber / 100)
								}
								onActiveBrightnessChange={(valueNumber) =>
									!(valueNumber < 0 || valueNumber > 100) &&
									!isNaN(valueNumber) &&
									setPeriodValue(period.sourceId ?? i, 'activeBrightness', valueNumber / 100)
								}
								i={i}
								disabledStart={period.fake || prevFake || (period.sourceId ?? i) === 0}
								disabledValues={period.fake}
								className={`${isDayRow && 'dayRow'} ${isNightRow && 'nightRow'}`}
								splitPeriod={() => splitPeriod(period.sourceId ?? i)}
								deletePeriod={() => deletePeriod(period.sourceId ?? i)}
							/>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}

function BrightnessTableRow({
	period,
	periods,
	is1212Mode,
	onStartChange,
	onIdleBrightnessChange,
	onActiveBrightnessChange,
	i,
	disabledStart,
	disabledValues,
	className,
	splitPeriod,
	deletePeriod
}) {
	const fake = period.fake

	const nextFake = periods[i + 1]?.fake
	const prevFake = periods[i - 1]?.fake

	return (
		<tr className={className}>
			<td className="num-cell">
				<div>{i + 1}.</div>
			</td>
			<td>
				<TimePicker
					value={secondsTodayToDate(period.start + (is1212Mode ? 43200 : 0))}
					disabled={disabledStart}
					onChange={(newDate) => onStartChange(dateToSecondsToday(newDate))}
				/>
			</td>
			<td className="brightness-input idle">
				<NumericInput
					small
					minimal
					value={Math.round(period.idleBrightness * 100)}
					disabled={disabledValues}
					onValueChange={onIdleBrightnessChange}
					selectAllOnFocus={true}
				/>
			</td>
			<td className="brightness-input motion">
				<NumericInput
					small
					minimal
					value={Math.round(period.activeBrightness * 100)}
					disabled={disabledValues}
					onValueChange={onActiveBrightnessChange}
					selectAllOnFocus={true}
				/>
			</td>
			<td className="row-action-cell">
				<div className="row-action">
					<div className="tickerAction">
						<ButtonGroup>
							{!(nextFake && prevFake) &&
								!(i == 0 && nextFake) &&
								!(i == periods.length - 1 && prevFake) &&
								!(i == 0 && periods.length == 1) && (
									<Button
										small
										minimal
										intent="danger"
										icon={<i className="fa-solid fa-minus"></i>}
										onClick={() => deletePeriod(i)}></Button>
								)}
							{!fake && (
								<Button
									small
									minimal
									intent="success"
									icon={<i className="fa-solid fa-plus"></i>}
									onClick={() => splitPeriod(i)}></Button>
							)}
						</ButtonGroup>
					</div>
				</div>
			</td>
		</tr>
	)
}

// ===================================================================

function Condition({ conditionType, parentConditions, data, onRemove, onValueChange }) {
	let condition = conditionTypes.find((c) => c.type == conditionType)
	if (!condition) {
		condition = { label: conditionType, icon: 'fa-question' }
	}
	let valueEl = null
	if (conditionType == 'weekdays') {
		const value = parentConditions.weekdays
		const abriviations = 'Mon,Tue,Wed,Thu,Fri,Sat,Sun'.split(',')
		valueEl = (
			<ButtonGroup>
				{abriviations.map((abriv, i) => (
					<WeekdayConditionButton key={`cwcb${i}`} value={value} numValue={i + 1} label={abriv} onValueChange={onValueChange} />
				))}
			</ButtonGroup>
		)
	} else if (conditionType == 'date') {
		const value = parentConditions.date
		valueEl = (
			<DateInput2
				className="bp5-small"
				value={value}
				onChange={onValueChange}
				parseDate={(date) => null}
				formatDate={(date) => DateTime.fromJSDate(date).toFormat('yyyy-LL-dd')}
				highlightCurrentDay={true}
			/>
		)
	} else if (conditionType == 'daylight' || conditionType == 'nightlight') {
		const value = parentConditions[conditionType]
		valueEl = (
			<>
				<div className="lineBreak">
					<NumericInput
						small
						className="smallNumeric"
						value={value.sunriseOffset}
						onValueChange={(val) => !isNaN(val) && onValueChange({ ...value, sunriseOffset: val })}
					/>
					<span className="small">{Lang.get('sunrise offset')}</span>
				</div>
				<div className="lineBreak">
					<NumericInput
						small
						className="smallNumeric"
						value={value.sunsetOffset}
						onValueChange={(val) => !isNaN(val) && onValueChange({ ...value, sunsetOffset: val })}
					/>
					<span className="small">{Lang.get('sunset offset')}</span>
				</div>
			</>
		)
	} else {
		valueEl = <pre>{JSON.stringify(data, null, 2)}</pre>
	}
	return (
		<div className="Condition">
			<div className="icon">
				<i className={`fa-fw fa-solid ${condition.icon}`}></i>
			</div>
			<div className="label">{condition.label}</div>
			<div className="value">{valueEl}</div>

			<div className="removeButton">
				<Button small minimal intent="danger" icon={<i className="fa-solid fa-xmark"></i>} onClick={onRemove} />
			</div>
		</div>
	)
}

// ===================================================================

function WeekdayConditionButton({ value, numValue, label, onValueChange }) {
	const isActive = value.includes(numValue)
	return (
		<Button
			small
			intent={isActive ? 'primary' : undefined}
			onClick={() => {
				onValueChange(isActive ? value.filter((v) => v != numValue) : [...value, numValue])
			}}>
			{label}
		</Button>
	)
}

// ===================================================================
