import './ILMap.scss'
import 'mapbox-gl/dist/mapbox-gl.css'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Lang from '../Lang'
import mapboxgl from 'mapbox-gl'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { Button, Menu, MenuItem, MenuDivider } from '@blueprintjs/core'
import { mapOverlays } from '../Assets/overlays'
import { capitalizeFirstLetter, getMapBoundsForDevices, isDeviceGateway, valueToHexColor } from '../Util'
import { GeolocateControl, Layer, Map, MapboxMap, Marker, Source } from 'react-map-gl'
import { LngLatBounds } from 'mapbox-gl'
import { DateTime } from 'luxon'
import { EVENT_LABELS } from '../Pages/Map/Components/Tabs/Events'

const COLORS = {
	RED: '#FF0000',
	GREEN: '#00FF00',
	BLUE: '#0000FF',
	YELLOW: '#FFFF00',
	ORANGE: '#FFA500',
	CYAN: '#00FFFF',
	MAGENTA: '#FF00FF',
	TRANSPARENT: 'transparent',
	WHITE: '#FFFFFF',
	BLACK: '#000000'
}

export default function ILMap({
	externalRef,
	profiles,
	devices,
	selectedDevice,
	onMarkerContextMenu,
	onMarkerClick,
	children,
	...rest
}) {
	const { setViewSetting, viewSettings } = useMapViewSettings()

	const internalRef = useRef(null)

	const mapRef = externalRef || internalRef

	const viewMenu = (
		<Menu>
			<MenuDivider title={Lang.get('View Type')} />
			<MenuItem
				icon={viewSettings.mapType == 'map' ? 'tick' : 'blank'}
				text={Lang.get('Map')}
				onClick={() => setViewSetting('mapType', 'map')}
				shouldDismissPopover={false}
			/>
			<MenuItem
				icon={viewSettings.mapType == 'satellite' ? 'tick' : 'blank'}
				text={Lang.get('Satellite')}
				onClick={() => setViewSetting('mapType', 'satellite')}
				shouldDismissPopover={false}
			/>

			<MenuDivider title={Lang.get('Icon Color')} />
			<MenuItem
				icon={viewSettings.icons.fillColorType == 'device-status' ? 'tick' : 'blank'}
				text={Lang.get('Device Status')}
				onClick={() => setViewSetting('icons.fillColorType', 'device-status')}
				shouldDismissPopover={false}
			/>
			<MenuItem
				icon={viewSettings.icons.fillColorType == 'lighting-profile' ? 'tick' : 'blank'}
				text={Lang.get('Lighting Profile')}
				onClick={() => setViewSetting('icons.fillColorType', 'lighting-profile')}
				shouldDismissPopover={false}
			/>
			<MenuItem
				icon={viewSettings.icons.fillColorType == 'report-health' ? 'tick' : 'blank'}
				text={Lang.get('Report Health')}
				onClick={() => setViewSetting('icons.fillColorType', 'report-health')}
				shouldDismissPopover={false}
			/>

			<MenuDivider title={Lang.get('Icon View')} />
			<MenuItem
				icon={viewSettings.icons.view.idLabel ? 'tick' : 'blank'}
				text={Lang.get('Show ID label')}
				onClick={() => setViewSetting('icons.view.idLabel', !viewSettings.icons.view.idLabel)}
				shouldDismissPopover={false}
			/>
		</Menu>
	)

	const mapStyle = useMemo(() => {
		switch (viewSettings.mapType) {
			case 'map':
				return 'mapbox://styles/mapbox/streets-v12'
			case 'satellite':
				return 'mapbox://styles/mapbox/satellite-v9'
			default:
				console.error('Unsupported map type:', viewSettings.mapType)
				return 'mapbox://styles/mapbox/streets-v12'
		}
	}, [viewSettings.mapType])

	// side view "burger":
	// offline events
	// offline
	// standby events
	// standby
	// online events
	// online
	const getZIndexForDevice = useCallback((device, hasActiveEvents) => {
		switch (device.status.onlineStatus) {
			case 'online': {
				return hasActiveEvents ? 2 : 1
			}
			case 'standby': {
				return hasActiveEvents ? 4 : 3
			}
			case 'offline': {
				return hasActiveEvents ? 6 : 5
			}
		}

		return 0
	}, [])

	useEffect(() => {
		if (!mapRef.current) return

		mapRef.current.resize()

		if (selectedDevice) {
			const curZoom = mapRef.current.getZoom()
			const targetZoom = 15

			const coords = [selectedDevice.longitude, selectedDevice.latitude]

			if (!coords.every((c) => c)) return

			const flyToOptions = {
				center: coords,
				...(curZoom < targetZoom && { zoom: targetZoom }),
				essential: true
			}
			mapRef.current.flyTo(flyToOptions)
		}
	}, [selectedDevice])

	const neighboursLayer = useMemo(() => {
		if (!selectedDevice) return null

		const features = selectedDevice.neighbours
			.map((n) => {
				const neighbour = devices.find((d) => d.id == n.id)

				if (!neighbour || !neighbour.latitude || !neighbour.longitude) return null

				return {
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: [
							[selectedDevice.longitude, selectedDevice.latitude],
							[neighbour.longitude, neighbour.latitude]
						]
					}
				}
			})
			.filter((n) => n)

		return (
			<Source
				id="neighbour-lines"
				type="geojson"
				data={{
					type: 'FeatureCollection',
					features
				}}>
				<Layer
					type="line"
					source={'neighbour-lines'}
					id="neighbour-lines-layer"
					paint={{ 'line-color': '#ff6600', 'line-width': 4, 'line-opacity': 0.6 }}></Layer>
			</Source>
		)
	}, [selectedDevice, devices])

	const mapOverlay = useMemo(() => {
		const overlay = mapOverlays.find((o) => o.accountId == window.App.account?.id)
		if (!overlay) return null

		return (
			<Source id={`${overlay.id}-layer`} type="image" url={overlay.image} coordinates={overlay.coordinates}>
				<Layer id="overlays-layer" type="raster" source={`${overlay.id}-layer`} paint={{ 'raster-opacity': 0.8 }}></Layer>
			</Source>
		)
	}, [])

	const onTrackUserLocationStart = useCallback(
		(coords) => {
			if (!mapRef.current || !coords) return

			mapRef.current?.fitBounds(getMapBoundsForDevices([...devices, { latitude: coords[0], longitude: coords[1] }]), {
				padding: 50,
				duration: 250
			})
		},
		[devices]
	)

	const geoControlRef = useRef()
	const userCoordsRef = useRef(null)
	const [waitingForCoordinates, setWaitingForCoordinates] = useState(false)

	useEffect(() => {
		if (!waitingForCoordinates) {
			if (onTrackUserLocationStart && userCoordsRef.current) {
				onTrackUserLocationStart(userCoordsRef.current)
			}
		}
	}, [waitingForCoordinates])

	function triggerLocate() {
		geoControlRef.current?.trigger()

		if (!userCoordsRef.current) {
			setWaitingForCoordinates(true)
		}

		if (onTrackUserLocationStart && userCoordsRef.current) {
			onTrackUserLocationStart(userCoordsRef.current)
		}
	}

	return (
		<div className="Map">
			<div className="tools">
				<Popover2 content={viewMenu} placement="bottom-end">
					<Button icon="eye-open" />
				</Popover2>

				<Button icon="locate" loading={waitingForCoordinates} onClick={triggerLocate} />
				{/* <Button icon="locate" loading={waitingForCoordinates} onClick={triggerLocate} /> */}
			</div>
			{/* <div className="container" ref={mapElRef} onClick={onClick} /> */}
			<Map
				mapboxAccessToken="pk.eyJ1IjoiZW1vcmFpdmlzIiwiYSI6ImNsOWNyOWF2dTF3bGMzcmxtOHUwd2d0YnYifQ.SyOOwd_WDYDpAdhPR5YK_g"
				mapStyle={mapStyle}
				ref={mapRef}
				initialViewState={{
					latitude: 56.9677,
					longitude: 24.1056,
					zoom: 6
				}}
				{...rest}>
				<GeolocateControl
					positionOptions={{ enableHighAccuracy: true }}
					fitBoundsOptions={{ zoom: 10 }}
					trackUserLocation={true}
					showUserHeading={true}
					ref={geoControlRef}
					onGeolocate={(e) => {
						const coords = [e.coords.latitude, e.coords.longitude]
						userCoordsRef.current = coords
						setWaitingForCoordinates(false)
					}}
				/>

				{children}

				{devices?.map((device, index) => {
					const isSelected = selectedDevice?.id == device.id

					const hasActiveEvents = device.events?.some((e) => e.isActive)

					const zIndex = getZIndexForDevice(device, hasActiveEvents)

					return (
						<Marker latitude={device.latitude} longitude={device.longitude} anchor="center" style={{ zIndex }} key={index}>
							<DeviceMarker
								device={device}
								profiles={profiles}
								viewSettings={viewSettings}
								isSelected={isSelected}
								hasActiveEvents={hasActiveEvents}
								onMarkerContextMenu={onMarkerContextMenu}
								onMarkerClick={onMarkerClick}
							/>
						</Marker>
					)
				})}

				{neighboursLayer}

				{mapOverlay}
			</Map>
		</div>
	)
}

function HoverTooltipContent({ device }) {
	const lastDataTimeDt = device.lastDataTime ? DateTime.fromSeconds(device.lastDataTime) : null

	const activeEvents = device.events?.filter((e) => e.isActive)

	return (
		<>
			<div className="id">{device.id}</div>

			{device.title && <div className="title">{device.title}</div>}

			{lastDataTimeDt &&
				(() => {
					let lastSeenText = ''

					const diff = DateTime.now().diff(lastDataTimeDt, ['months', 'weeks', 'days', 'hours', 'minutes']).toObject()
					if (diff.months > 0) {
						lastSeenText = Lang.get('%s months ago', Math.round(diff.months))
					} else if (diff.weeks > 0) {
						lastSeenText = Lang.get('%s weeks ago', Math.round(diff.weeks))
					} else if (diff.days > 0) {
						lastSeenText = Lang.get('%s days ago', Math.round(diff.days))
					} else if (diff.hours > 0) {
						lastSeenText = Lang.get('%s hours ago', Math.round(diff.hours))
					} else if (diff.minutes > 0) {
						if (Math.round(diff.minutes) <= 1) {
							lastSeenText = Lang.get('Just now')
						} else {
							lastSeenText = Lang.get('%s minutes ago', Math.round(diff.minutes))
						}
					}

					return (
						<>
							<div className="last-data-time">{lastDataTimeDt.toFormat('yyyy-LL-dd HH:mm:ss')}</div>
							<div className="last-data-time">{lastSeenText}</div>
						</>
					)
				})()}

			{device.lightingProfileName && (
				<div className="icon-text-box">
					<div className="lighting-profile-icon-box" style={{ backgroundColor: device.lightingProfileColor ?? 'black' }}>
						<i className="fa-fw fa-solid fa-chart-simple" />
					</div>

					<span>{device.lightingProfileName}</span>
				</div>
			)}

			{device.status && (
				<div className="icon-text-box">
					<div className="status-ring" style={{ borderColor: getRingColorForDevice(device) }}></div>

					<span>{ONLINE_STATUS_LABELS[device.status.onlineStatus]}</span>
				</div>
			)}

			{device.status && (
				<div className="icon-text-box">
					<div className="glow-circle" style={{ backgroundColor: getFillColorForDeviceGlowStatus(device) }}></div>

					<span>{GLOW_STATUS_LABELS[device.status.currentGlow]}</span>
				</div>
			)}

			{activeEvents?.length > 0 && (
				<div className="active-events">
					<div>{Lang.get('Active events')}:</div>
					<ul>
						{activeEvents.map((event, i) => (
							<li key={`htaeli${device.id}${i}`}>{EVENT_LABELS[event.type]}</li>
						))}
					</ul>
				</div>
			)}
		</>
	)
}

const ONLINE_STATUS_LABELS = {
	online: Lang.get('Online'),
	standby: Lang.get('Standby'),
	offline: Lang.get('Offline')
}

const GLOW_STATUS_LABELS = {
	glowing: Lang.get('Glowing'),
	'not-glowing': Lang.get('Not Glowing'),
	unknown: Lang.get('Unknown')
}

function getRingColorForDevice(device) {
	if (device.status.onlineStatus === 'online') return COLORS.GREEN
	if (device.status.onlineStatus === 'standby') return COLORS.ORANGE

	return COLORS.RED
}

function getFillColorForDeviceGlowStatus(device) {
	if (device.status.currentGlow === 'glowing') {
		return COLORS.GREEN
	} else if (device.status.currentGlow === 'not-glowing') {
		return COLORS.BLACK
	} else if (device.status.currentGlow === 'unknown') {
		return COLORS.WHITE
	}

	return 'gray'
}

function useMapViewSettings() {
	const [viewSettings, _setViewSettings] = useState({
		mapType: 'map',
		icons: {
			// users: device-status, lighting-profile
			// admin: report-health
			fillColorType: 'device-status',
			view: {
				idLabel: false
			}
		}
	})

	// key (string): mapType, icons.color, icons.view.idLabel
	function setViewSetting(key, value) {
		const path = key.split('.')

		let currentObject = viewSettings

		// Iterate through the path, updating nested objects
		path.forEach((segment, index) => {
			if (index === path.length - 1) {
				// Last segment: set the value
				currentObject[segment] = value
			} else {
				// Intermediate segment: ensure it exists and is an object
				if (!currentObject[segment] || typeof currentObject[segment] !== 'object') {
					throw new Error(`Invalid path: ${key}`)
				}
				currentObject = currentObject[segment]
			}
		})

		// Update the state with the modified object
		_setViewSettings({ ...viewSettings })
	}

	return { setViewSetting, viewSettings }
}

export function DeviceMarker({
	device,
	profiles,
	viewSettings,
	isSelected,
	hasActiveEvents,
	onMarkerContextMenu,
	onMarkerClick,
	hideLightingProfileShadow = false
}) {
	const getCoreColorForDevice = useCallback(
		(device) => {
			if (device.status.supposedGlow === 'disabled' || viewSettings.icons.fillColorType != 'device-status')
				return COLORS.TRANSPARENT

			if (device.status.supposedGlow === 'glowing') return COLORS.GREEN
			if (device.status.supposedGlow === 'not-glowing') return COLORS.BLACK

			return COLORS.WHITE
		},
		[viewSettings.icons.fillColorType]
	)

	const getFillColorForDevice = useCallback(
		(device) => {
			let ret = 'gray'

			switch (viewSettings.icons.fillColorType) {
				case 'device-status': {
					return getFillColorForDeviceGlowStatus(device)
				}

				case 'lighting-profile': {
					if (profiles) {
						const profile = profiles.find((p) => p.id == device.lightingProfileId)
						if (profile) {
							ret = profile.color
						}
					}

					break
				}
				case 'report-health': {
					const reportCount = device.reportHealth
					const healthPercentage = reportCount / 30
					ret = valueToHexColor(healthPercentage)

					break
				}

				default: {
					console.error('Unsupported marker fill color type:', viewSettings.icons.fillColorType)
					break
				}
			}

			return ret
		},
		[profiles, viewSettings.icons.fillColorType]
	)

	const fillColor = getFillColorForDevice(device)
	const ringColor = getRingColorForDevice(device)
	const coreColor = getCoreColorForDevice(device)

	return (
		<div
			style={{
				'--fillColor': fillColor,
				'--ringColor': ringColor,
				'--coreColor': coreColor,
				'--lightingProfileColor': !hideLightingProfileShadow ? device.lightingProfileColor ?? 'black' : 'transparent'
			}}
			className={`device-marker ${isSelected ? 'selected' : ''} ${device.customIcon === 'microchip' ? 'segment' : ''}`}
			onContextMenu={(e) => onMarkerContextMenu(e, device)}
			onClick={() => onMarkerClick(device)}>
			<div className="core"></div>

			{viewSettings.icons.view.idLabel && <div className="label">{device.id}</div>}

			{isDeviceGateway(device) && <i className="gateway-icon fa-fw fa-duotone fa-wifi"></i>}

			{hasActiveEvents && <i className="event-icon fa-fw fa-solid fa-exclamation-triangle"></i>}

			<div className="hover-tooltip-container container-shadow">
				<HoverTooltipContent device={device} />
			</div>
		</div>
	)
}
