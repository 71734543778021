import './App.scss'

import API from '../API'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Settings } from 'luxon'

import MapPage from '../Pages/Map'
import LoginPage from '../Pages/Login'
import PlaceholderPage from '../Pages/Placeholder'
import ReportsPage from '../Pages/Reports'
import SettingsPage from '../Pages/Settings'
import InspectorPage from '../Pages/Inspector'

import { isAdmin, isInspector } from '../Util'
import AdminRFToolsPage from '../Pages/Admin/RFTools'
import AdminRFTrafficPage from '../Pages/Admin/RFTraffic'
import AdminGSMToolsPages from '../Pages/Admin/GSMTools'
import AdminFirmaresPage from '../Pages/Admin/Firmwares'
import AdminConsolePage from '../Pages/Admin/Console'

import { OverlayToaster, Position } from '@blueprintjs/core'
import Placeholder from './Placeholder'
import { Settings } from 'luxon'
export const AppToaster = OverlayToaster.createAsync({
	position: Position.TOP
})

export default class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			status: 'CONNECTING'
		}
		window.App = this
		this.user = null
		this.account = null
		this.accounts = null
		this.additionalData = null
	}

	componentDidMount() {
		API.addConnectionListener(this, async (connected) => {
			console.log('[App] connected =', connected, this)

			if (connected) {
				const sessionId = localStorage.getItem('sessionId')
				if (sessionId) {
					let res = await API.call('authorize', { sessionId })
					if (res.user) {
						console.log('got user', res.user)
						this.user = res.user
						this.account = res.account
						this.accounts = res.accounts
						this.additionalData = res.additionalData

						Settings.defaultZone = res.account.timeZone
						this.setState({ status: 'ONLINE' })
						return
					} else {
						localStorage.removeItem('sessionId')
					}
				}
				this.user = null
				this.account = null
				this.accounts = null
				this.additionalData = null
				this.setState({ status: 'NEEDS-LOGIN' })
			} else {
				this.setState({ status: 'CONNECTING' })
			}
		})
	}

	componentWillUnmount() {
		API.removeListener(this)
	}

	render() {
		if (this.state.status == 'CONNECTING') {
			return <Placeholder className="white-bg" loading label={'Connecting ...'} />
		} else if (this.state.status == 'NEEDS-LOGIN') {
			return <LoginPage />
		} else if (this.state.status == 'ONLINE') {
			return (
				<BrowserRouter>
					<Routes>
						<Route
							path="*"
							element={
								<PlaceholderPage
									key="404"
									title="Kļūda 404"
									message="Lapa nav atrasta!"
									icon="fa-duotone fa-triangle-exclamation"
								/>
							}
						/>

						<Route path="/" element={<MapPage />} />
						<Route path="/reports" element={<ReportsPage />} />
						<Route path="/settings" element={<SettingsPage />} />
						{isInspector() && <Route path="/inspector" element={<InspectorPage />} />}

						{isAdmin() && (
							<>
								<Route path="/admin/rf-tools" element={<AdminRFToolsPage />} />
								<Route path="/admin/rf-traffic" element={<AdminRFTrafficPage />} />
								<Route path="/admin/gsm-tools" element={<AdminGSMToolsPages />} />
								<Route path="/admin/firmwares" element={<AdminFirmaresPage />} />
								<Route path="/admin/console" element={<AdminConsolePage />} />
							</>
						)}
					</Routes>
				</BrowserRouter>
			)
		}
	}
}
