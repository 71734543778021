import './index.scss'

import React, { useState } from 'react'
import { DeviceDetailsBlock, keyDetails } from '../..'
import DeviceChoice from '../../../DeviceChoice'
import API, { RFCommand, RFPacket } from '../../../../../../API'
import { Button, ButtonGroup, InputGroup, Spinner } from '@blueprintjs/core'
import { AdminOnly, dali2percents } from '../../../../../../Util'
import Lang from '../../../../../../Lang'
import Time from '../../../../../../Components/Time'

export default function GeneralTabPanel({ device, view }) {
	return (
		<>
			<DeviceDetailsInfoBlock device={device} view={view} />
			<AdminOnly>
				<DeviceDetailsActionBlock device={device} view={view} />
			</AdminOnly>
			<DeviceNeighboursBlock device={device} view={view} />
			<DeviceDetailsD4IBlock device={device} />
		</>
	)
}

function DeviceDetailsD4IBlock(props) {
	const d4i = props.device.lastCommands.find((c) => c.commandName == 'D4I')
	if (d4i?.payload?.subCommandName != 'MEASUREMENT_REPORT') {
		return null
	}

	return (
		<DeviceDetailsBlock title="D4I Report">
			<div className="reportLine">
				<span>Received</span>
				<span className="value">
					<Time timestamp={d4i.time / 1000} />
				</span>
			</div>
			{Object.keys(keyDetails).map((key) => {
				const value = d4i.payload[key]
				if (value === undefined) {
					return null
				}
				const keyData = keyDetails[key]
				let formattedValue = value
				if (keyData.format) {
					formattedValue = keyData.format(value)
				}
				if (keyData.type == 'duration') {
					formattedValue = (value / 3600).toFixed(0) + ' h'
				} else if (keyData.type == 'dalivalue') {
					formattedValue = /*'(' + value + ') ' + */ dali2percents(value) + '%'
				}
				return (
					<div className="reportLine" key={`report-line-${key}`}>
						<span>{keyData.label}</span>
						<span className="value">
							{formattedValue} {keyData.unit}
						</span>
					</div>
				)
			})}
		</DeviceDetailsBlock>
	)
}

function DeviceNeighboursBlock({ device, view }) {
	const [busy, setBusy] = useState(false)

	const neighbourIds = device.neighbours.map((d) => d.id)

	async function saveNeighbours(newNeighbourIds) {
		const added = newNeighbourIds.filter((id) => !neighbourIds.includes(id))
		const removed = neighbourIds.filter((id) => !newNeighbourIds.includes(id))

		if (added.length == 0 && removed.length == 0) return

		setBusy(true)

		await view.setNeighbours(device, [
			...added.map((id) => ({ id, multiplier: 1 })),
			...removed.map((id) => ({ id, multiplier: 0 }))
		])

		setBusy(false)
	}

	return (
		<DeviceDetailsBlock title={Lang.get('Neighbour Lights')} loading={busy}>
			<DeviceChoice deviceIds={neighbourIds} onChange={saveNeighbours} />
		</DeviceDetailsBlock>
	)
}

// =============================================================================

class DeviceDetailsInfoBlock extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			busy: false,
			response: null,
			device: props.device,
			deviceUnsaved: false
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.device.id !== this.props.device.id) {
			this.setState({ device: this.props.device })
		}
	}

	updateDeviceInfo(event) {
		const device = this.state.device
		device.info = event.target.value
		this.setState({
			device: device,
			deviceUnsaved: true
		})
	}
	updateDeviceTitle(event) {
		const device = this.state.device
		device.title = event.target.value
		this.setState({
			device: device,
			deviceUnsaved: true
		})
	}

	updateDeviceValue(key, event) {
		const device = this.state.device
		device[key] = event.target.value
		this.setState({
			device: device,
			deviceUnsaved: true
		})
	}

	async save() {
		this.setState({ busy: true })

		const deviceUpdateData = {
			id: this.state.device.id,
			info: this.state.device.info,
			title: this.state.device.title
		}

		if (this.state.device.definedNominalPower) {
			deviceUpdateData.definedNominalPower = this.state.device.definedNominalPower
		}

		await API.call('Admin.Device.Update', deviceUpdateData)
		this.props.view.selectDevice(this.state.device)
		this.setState({ busy: false, deviceUnsaved: false })
	}

	render() {
		const d = this.state.device
		return (
			<DeviceDetailsBlock className="device-info-block">
				<div>
					<span>{Lang.get('Last Heard')}: </span>
					<span className="value">
						<Time timestamp={d.lastDataTime} />
					</span>
				</div>
				<div>
					<span>{Lang.get('Title')}: </span>
					<span className="value">{d.title}</span>
				</div>
				<AdminOnly>
					<div>
						<InputGroup
							disabled={this.state.busy}
							value={this.state.device.title ?? ''}
							onChange={(e) => this.updateDeviceValue('title', e)}
						/>
					</div>
					<div>
						<span>{Lang.get('Info')}: </span>
						<InputGroup
							disabled={this.state.busy}
							value={this.state.device.info ?? ''}
							onChange={(e) => this.updateDeviceValue('info', e)}
						/>
					</div>
					<div>
						<span>{Lang.get('Nominal Power')} (W): </span>
						<InputGroup
							disabled={this.state.busy}
							value={this.state.device.definedNominalPower ?? ''}
							onChange={(e) => this.updateDeviceValue('definedNominalPower', e)}
							type="number"
						/>
					</div>

					<div>
						<span>{Lang.get('Serial Nr')}: </span>
						<small className="value">{d.serialNr}</small>
					</div>
					{d.identity && (
						<div>
							<span>{Lang.get('Firmware')}: </span>
							<span className="value">{d.identity.firmwareVersion}</span>
						</div>
					)}

					{this.state.deviceUnsaved && (
						<div>
							<Button
								disabled={this.state.busy}
								icon={this.state.busy ? <Spinner className="spinner-icon white" /> : 'floppy-disk'}
								text={Lang.get('Save')}
								onClick={() => this.save()}
								fill
								intent="primary"
							/>
						</div>
					)}
				</AdminOnly>
			</DeviceDetailsBlock>
		)
	}
}

// =============================================================================

class DeviceDetailsActionBlock extends React.Component {
	action(action) {
		const p = new RFPacket()

		if (action == 'on') {
			p.command = RFCommand.RELAY_TEST
			p.data = Buffer.from('01', 'hex')
		} else if (action == 'off') {
			p.command = RFCommand.RELAY_TEST
			p.data = Buffer.from('00', 'hex')
		} else if (action == 'blink') {
			p.command = RFCommand.IDENTIFY
		} else {
			return
		}

		API.sendPacket(p, this.props.device)
	}

	render() {
		const d = this.props.device
		return (
			<DeviceDetailsBlock title={Lang.get('Actions')} className="actions">
				<DeviceDetailsPingButton device={d} />
				<Button icon="flash" text="Blink DALI" onClick={() => this.action('blink')} />
				<ButtonGroup fill>
					<Button icon="power" text="Relay ON" onClick={() => this.action('on')} />
					<Button text="Relay OFF" onClick={() => this.action('off')} />
				</ButtonGroup>
			</DeviceDetailsBlock>
		)
	}
}

export class DeviceDetailsPingButton extends React.Component {
	constructor(props) {
		super(props)
		this.state = { busy: false, response: null }
	}

	async ping() {
		if (this.state.busy) return
		this.setState({ busy: true, response: null })

		let benchmark = new Date()

		const p = new RFPacket()
		p.command = RFCommand.SCAN
		const r = await API.sendPacketAndWaitResponse(
			p,
			this.props.device,
			{
				deviceId: this.props.device.id,
				command: RFCommand.SCAN_REPORT
			},
			this.props.preferGateway ?? -1
		)
		if (r) {
			benchmark = new Date().getTime() - benchmark.getTime()
			this.setState({ busy: false, response: benchmark })
		} else {
			this.setState({ busy: false, response: -1 })
		}
	}

	render() {
		if (this.state.busy) {
			return <Button loading />
		} else {
			if (this.state.response) {
				if (this.state.response == -1) {
					return <Button icon="feed" intent="danger" text="Ping Failed" onClick={() => this.ping()} />
				} else {
					return (
						<Button icon="feed" intent="success" text={'Pinged in ' + this.state.response + ' ms'} onClick={() => this.ping()} />
					)
				}
			} else {
				return <Button icon="feed" text={Lang.get('Ping')} onClick={() => this.ping()} />
			}
		}
	}
}
