import { Button, Menu, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import Lang from '../../../../../../Lang'
import { EVENT_LABELS } from '..'

export default function FilterButton({ value, onChange }) {
	function toggleEventType(eventType) {
		if (value.includes(eventType)) {
			onChange(value.filter((type) => type !== eventType))
		} else {
			onChange([...value, eventType])
		}
	}

	const filterMenu = (
		<Menu>
			{Object.keys(EVENT_LABELS).map((key) => (
				<MenuItem
					key={`etfmi${key}`}
					text={EVENT_LABELS[key]}
					icon={value.includes(key) ? 'tick' : 'blank'}
					onClick={() => toggleEventType(key)}
				/>
			))}
		</Menu>
	)

	return (
		<Popover2 content={filterMenu} position="bottom-right">
			<Button text={Lang.get('Filter by type')} icon="filter" rightIcon="caret-down" />
		</Popover2>
	)
}
