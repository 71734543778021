import './index.scss'

import { useState } from 'react'
import Placeholder from '../../../../../Components/Placeholder'
import DeviceList from './Components/DeviceList'
import { Button } from '@blueprintjs/core'
import Lang from '../../../../../Lang'

export default function DevicesTab({
	wantsNewProfile,
	devices,
	profiles,
	selectedProfile,
	selectedDevice,
	selectProfile,
	selectDevice
}) {
	const [searchingFor, setSearchingFor] = useState('')

	return (
		<div className="devices-tab">
			<header>
				<SearchInput value={searchingFor} onChange={(e) => setSearchingFor(e.target.value)} />
				<Button
					// text={Lang.get('Create New Profile')}
					icon="add"
					minimal
					intent="primary"
					onClick={() => wantsNewProfile({ version: 2 })}
				/>
			</header>
			<div className="devices-tab-content">
				{devices && profiles ? (
					<DeviceList
						devices={devices}
						lightProfiles={profiles}
						searchingFor={searchingFor}
						selectedProfile={selectedProfile}
						selectedDevice={selectedDevice}
						selectProfile={selectProfile}
						selectDevice={selectDevice}
					/>
				) : (
					<Placeholder loading />
				)}
			</div>
		</div>
	)
}

function SearchInput(props) {
	return (
		<div className="SearchInput">
			<i className="fa-fw fa-duotone fa-search" />
			<input type="text" placeholder={Lang.get('Search...')} value={props.value} onChange={props.onChange} />
		</div>
	)
}
