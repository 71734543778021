import './index.scss'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import API from '../../API'
import Lang from '../../Lang'
import ILMap from '../../Components/ILMap'
import Page from '../../Components/Page'
import DeviceDetails from './Components/DeviceDetails'
import Placeholder from '../../Components/Placeholder'
import ProfileDetails from './Components/ProfileDetails'
import { Menu, MenuItem, MenuDivider, ContextMenuPopover, Tabs, Tab } from '@blueprintjs/core'

import { profileTemplate } from './Components/ProfileDetails/ProfileV2Editor'
import { getMapBoundsForDevices } from '../../Util'
import { useMemo } from 'react'
import DevicesTab from './Components/Tabs/Devices'
import EventsTab from './Components/Tabs/Events'
import { useQueryParamsState } from '../../Hooks/useQueryParamsState'

export default function MapPage() {
	const [events, setEvents] = useState(null)
	const [devices, setDevices] = useState(null)
	const [profiles, setProfiles] = useState(null)

	const [contextMenuDeviceId, setContextMenuDeviceId] = useState(null)
	const [contextMenuPosition, setContextMenuPosition] = useState({ left: 0, top: 0 })
	const [contextMenuOpen, setContextMenuOpen] = useState(false)

	const [urlTargetDevice, setUrlTargetDevice] = useQueryParamsState('target', '')
	console.log(urlTargetDevice)

	const [selectedDevice, setSelectedDevice] = useState(null)
	const [selectedProfile, setSelectedProfile] = useState(null)
	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [activeEventCount, setActiveEventCount] = useState(0)

	const mapRef = useRef()

	async function setDeviceProfile(dev, newProfile) {
		// Ignore if profiles isn't changing
		if (dev.lightingProfileId == newProfile.id) return

		const res = await API.call('LightingProfile.AddDevice', { deviceId: dev.id, profileId: newProfile.id })
		if (!res || !res.success) {
			console.error('Failed to add device to profile', res)
			return
		}

		// Remove device from current profile
		if (dev.lightingProfileId) {
			setProfiles((profiles) => {
				return profiles.map((profile) => {
					if (profile.id === dev.lightingProfileId) {
						return {
							...profile,
							deviceIds: profile.deviceIds.filter((id) => id != dev.id)
						}
					}
					return profile
				})
			})
		}

		// Add new profile to device
		setDevices((devices) => {
			return devices.map((device) => {
				if (device.id === dev.id) {
					return {
						...device,
						lightingProfileId: newProfile.id
					}
				}

				return device
			})
		})

		// Add device to new profile
		setProfiles((profiles) => {
			return profiles.map((profile) => {
				if (profile.id === newProfile.id) {
					return {
						...profile,
						deviceIds: profile.deviceIds.concat(dev.id)
					}
				}

				return profile
			})
		})
	}

	const contextMenuContent = useMemo(() => {
		if (!devices) return null

		const contextMenuDevice = devices.find((d) => d.id == contextMenuDeviceId)
		if (!contextMenuDevice) return null

		const neighbourValue = selectedDevice?.neighbours.find((n) => n.id == contextMenuDevice.id)

		return (
			<Menu>
				<MenuItem active={true} text={Lang.get('Device') + ' ' + contextMenuDevice.id} shouldDismissPopover={false} />

				{selectedDevice && selectedDevice.id != contextMenuDevice.id && (
					<>
						<MenuDivider />

						{neighbourValue ? (
							<MenuItem
								icon="delete"
								text={Lang.get('Remove Neighbour')}
								onClick={() => setNeighbours(selectedDevice, [{ ...contextMenuDevice, multiplier: 0 }])}
							/>
						) : (
							<MenuItem
								icon="add"
								text={Lang.get('Add Neighbour')}
								onClick={() => setNeighbours(selectedDevice, [{ ...contextMenuDevice, multiplier: 1 }])}
							/>
						)}
					</>
				)}

				<MenuDivider />

				{profiles?.length > 0 &&
					profiles
						.filter((p) => !p.disabled)
						.map((p) => {
							const selected = contextMenuDevice?.lightingProfileId == p.id

							return (
								<MenuItem
									shouldDismissPopover={false}
									key={`cm-p-${p.id}`}
									icon={selected ? 'tick' : 'blank'}
									text={p.title}
									onClick={() => setDeviceProfile(contextMenuDevice, p)}
								/>
							)
						})}
			</Menu>
		)
	}, [contextMenuDeviceId, profiles, devices, selectedDevice])

	async function setNeighbours(device, neighbours) {
		const updatedNeighbours = device.neighbours.filter(
			(n) => !neighbours.some((neigh) => neigh.id == n.id && neigh.multiplier == 0)
		)

		neighbours.forEach((neighbour) => {
			if (neighbour.multiplier > 0) {
				updatedNeighbours.push({ id: neighbour.id, multiplier: neighbour.multiplier })
			}
		})

		setDevices((devices) => {
			return devices.map((dev) => {
				if (dev.id == device.id) {
					const newDevice = {
						...dev,
						neighbours: updatedNeighbours
					}

					console.log({
						oldDevice: {
							id: dev.id,
							neighbours: dev.neighbours
						},
						newDevice: {
							id: newDevice.id,
							neighbours: newDevice.neighbours
						}
					})

					device = newDevice
					setSelectedDevice(newDevice)
					selectDevice(newDevice)
					return newDevice
				}
				return dev
			})
		})

		let newNeighbours = updatedNeighbours.map((n) => ({ id: n.id, multiplier: n.multiplier }))

		await API.call('Device.SetNeighbours', { deviceId: device.id, neighbours: newNeighbours })
	}

	function selectDevice(device) {
		setSelectedDevice(device)

		if (device) setSelectedProfile(null)
	}

	function selectProfile(profile) {
		setSelectedProfile(profile)

		if (profile) selectDevice(null)
	}

	function onMarkerContextMenu(e, device) {
		setContextMenuPosition({ left: e.clientX, top: e.clientY })
		setContextMenuOpen(true)
		setContextMenuDeviceId(device.id)
	}

	function onMarkerClick(device) {
		selectDevice(device)
	}

	async function hydrate(zoom = true) {
		let [devices, profiles, events] = await Promise.all([API.getDevices(), API.getProfiles(), API.getEvents()])

		devices = devices.devices ?? []

		// prep some properties
		for (const device of devices) {
			const deviceEvents = events.filter((e) => e.deviceId == device.id)
			device.events = [...(device.events ?? []), ...deviceEvents]

			const deviceLightingProfile = profiles.find((p) => p.id == device.lightingProfileId)
			if (deviceLightingProfile) {
				device.lightingProfileName = deviceLightingProfile.title
				device.lightingProfileColor = deviceLightingProfile.color
			}
		}

		console.log({
			devices,
			profiles,
			events
		})

		setDevices(devices)
		setProfiles(profiles)
		setEvents(events)
		setSidebarOpen(true)
		setActiveEventCount(events.filter((e) => e.isActive).length)

		if (devices.length > 0 && zoom) mapRef.current?.fitBounds(getMapBoundsForDevices(devices), { padding: 50, duration: 250 })

		if (urlTargetDevice) {
			const targetDevice = devices.find((d) => d.id == urlTargetDevice)
			if (targetDevice) {
				selectDevice(targetDevice)
				setUrlTargetDevice('')
			}
		}
	}

	function wantsNewProfile(args) {
		let newProfile = {
			id: 0,
			title: Lang.get('New Profile'),
			daliSpeed: 1,
			motionTime: 60,
			deviceIds: []
		}

		if (args.version == 1) {
			newProfile = {
				...newProfile,
				version: 1,
				sunriseOffset: 0,
				sunsetOffset: 0,
				dayBrightness: 0,
				nightBrightness: 70,
				nightBrightnessMotion: 100,
				nightBrightnessN1: 90,
				nightBrightnessN2: 80,
				nightBrightnessN3: 0,
				nightBrightnessN4: 0,
				nightBrightnessN5: 0
			}
		} else if (args.version == 2) {
			newProfile = {
				...newProfile,
				version: 2,
				title: Lang.get('New Profile'),
				...JSON.parse(JSON.stringify(profileTemplate))
			}
		}

		selectProfile(newProfile)
	}

	const [selectedTabId, setSelectedTabId] = useState('devices')
	// useEffect(() => {
	// 	if (devices && devices.length > 0) mapRef.current?.fitBounds(getMapBoundsForDevices(devices), { padding: 50, duration: 250 })
	// }, [selectedTabId])

	useEffect(() => hydrate(), [])

	// because layout shift
	useEffect(() => mapRef.current?.resize(), [selectedDevice, selectedProfile, sidebarOpen])

	return (
		<Page title={Lang.get('Devices')} icon="fa-duotone fa-router" className={'MapPage'} noPadding>
			<Placeholder overlay overlayVisible={devices == null} loading label={Lang.get('Loading') + ' ...'} />

			<div className={`container sideBar ${sidebarOpen && 'open'}`}>
				<Tabs className="sidebarTabs" onChange={(tab) => setSelectedTabId(tab)} selectedTabId={selectedTabId}>
					<Tab
						id="devices"
						title={Lang.get('Devices')}
						panel={
							<DevicesTab
								wantsNewProfile={wantsNewProfile}
								devices={devices}
								profiles={profiles}
								selectedProfile={selectedProfile}
								selectedDevice={selectedDevice}
								selectProfile={selectProfile}
								selectDevice={selectDevice}
							/>
						}
					/>
					<Tab
						id="events"
						title={Lang.get('Events')}
						panel={<EventsTab events={events} devices={devices} selectedDevice={selectedDevice} selectDevice={selectDevice} />}
						{...(activeEventCount > 0 ? { className: 'has-active-events', tagContent: activeEventCount } : {})}
						tagProps={{
							intent: activeEventCount > 0 ? 'warning' : 'none',
							style: {
								textAlign: 'center'
							}
						}}
					/>
				</Tabs>
			</div>

			<div className={`container fakeSideBar ${sidebarOpen && 'open'}`}></div>

			{selectedDevice && (
				<DeviceDetails
					device={selectedDevice}
					view={{
						selectDevice,
						setNeighbours
					}}
				/>
			)}

			{selectedProfile && <ProfileDetails profile={selectedProfile} selectProfile={selectProfile} reload={hydrate} />}
			<div className="MapContainer">
				<ContextMenuPopover
					isOpen={contextMenuOpen}
					onClose={() => setContextMenuOpen(false)}
					targetOffset={contextMenuPosition}
					content={contextMenuContent}
				/>

				<ILMap
					externalRef={mapRef}
					profiles={profiles}
					devices={devices}
					selectedDevice={selectedDevice}
					onMarkerContextMenu={onMarkerContextMenu}
					onMarkerClick={onMarkerClick}
				/>
			</div>
		</Page>
	)
}
