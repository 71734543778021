import './MainMenu.scss'

import React from 'react'
import Lang from '../Lang'
import { AdminOnly, isInspector } from '../Util'
import { Popover2 } from '@blueprintjs/popover2'
import LanguageSelector from './LanguageSelector'
import { Menu, MenuItem, Position } from '@blueprintjs/core'
import { NavLink, useMatch, useNavigate } from 'react-router-dom'

import logoUrl from '../Assets/logo.png'
import AccountSelector from './AccountSelector'
import { useMediaQuery } from '@react-hookz/web'

function MenuButton(props) {
	return (
		<NavLink to={props.path} className="MenuButton">
			<i className={'fa-fw ' + props.icon} /> {props.label}
		</NavLink>
	)
}

function AdminMenuButtonItem(props) {
	let navigate = useNavigate()
	return (
		<MenuItem
			text={props.label}
			icon={<i className={'fa-fw fa-duotone ' + props.icon} />}
			onClick={() => {
				navigate(props.path)
			}}
		/>
	)
}

function AdminMenuButton(props) {
	const adminItem = useMatch('/admin/*')
	return (
		<div className={'MenuButton' + (adminItem ? ' active' : '')}>
			<i className={'fa-fw fa-duotone fa-screwdriver-wrench'} /> Admin
			<i className="fa-fw fa-solid fa-caret-down" />
		</div>
	)
}

export default function MainMenu() {
	const cantFitButtons = useMediaQuery('(max-width: 1200px)')

	const userMenu = (
		<Menu>
			<MenuItem
				icon="log-out"
				text={Lang.get('Log Out')}
				intent="danger"
				onClick={() => {
					localStorage.removeItem('sessionId')
					location.reload()
				}}
			/>
		</Menu>
	)

	let adminMenu = (
		<Menu>
			<AdminMenuButtonItem path="/admin/rf-tools" icon="fa-duotone fa-satellite-dish" label="RF Tools" />
			<AdminMenuButtonItem path="/admin/gsm-tools" icon="fa-duotone fa-mobile-signal-out" label="GSM Tools" />
			<AdminMenuButtonItem path="/admin/firmwares" icon="fa-duotone fa-file-binary" label="Firmwares" />
			<AdminMenuButtonItem path="/admin/console" icon="fa-duotone fa-square-terminal" label="Console" />
		</Menu>
	)

	return (
		<div className="MainMenu">
			{!cantFitButtons && (
				<NavLink to="/" className="LogoContainer">
					<img src={logoUrl} className="Logo" />
				</NavLink>
			)}

			<div className="AccountHeader">
				<AccountSelector hideActive={cantFitButtons} />
			</div>

			<div className="MenuItems">
				{cantFitButtons ? (
					<Popover2
						popoverClassName="small-popover"
						content={
							<>
								<MenuButton path="/" icon="fa-duotone fa-map" label={Lang.get('Map')} />
								<MenuButton path="/reports" icon="fa-duotone fa-chart-simple" label={Lang.get('Reports')} />
								<MenuButton path="/settings" icon="fa-duotone fa-gear" label={Lang.get('Settings')} />
								{isInspector() && <MenuButton path="/inspector" icon="fa-duotone fa-wrench" label={Lang.get('Inspector')} />}

								<AdminOnly>
									<Popover2 content={adminMenu}>
										<AdminMenuButton />
									</Popover2>
								</AdminOnly>
							</>
						}>
						<div className="MenuButton">
							<i className="fa-solid fa-bars"></i>
						</div>
					</Popover2>
				) : (
					<div className="row">
						<MenuButton path="/" icon="fa-duotone fa-map" label={Lang.get('Map')} />
						<MenuButton path="/reports" icon="fa-duotone fa-chart-simple" label={Lang.get('Reports')} />
						<MenuButton path="/settings" icon="fa-duotone fa-gear" label={Lang.get('Settings')} />
						{isInspector() && <MenuButton path="/inspector" icon="fa-duotone fa-wrench" label={Lang.get('Inspector')} />}

						<AdminOnly>
							<Popover2 content={adminMenu}>
								<AdminMenuButton />
							</Popover2>
						</AdminOnly>
					</div>
				)}
			</div>

			{/* <div className="Spacer" /> */}

			<LanguageSelector />

			<div className="User">
				<Popover2 content={userMenu} position={Position.BOTTOM}>
					<div className="UserContent">
						<div className="UserIcon">
							<i className="fa-fw fa-solid fa-user" />
						</div>
						<div className="UserName">{window.App.user.name}</div>
						<div className="Spacer" />
						<div className="UserMenu">
							<i className="fa-fw fa-solid fa-caret-down" />
						</div>
					</div>
				</Popover2>
			</div>
		</div>
	)
}
