import './index.scss'

import { useMemo } from 'react'
import { DeviceDetailsBlock } from '../..'
import { EventsListBlock } from '../../../Tabs/Events'

export default function EventsTabPanel({ device }) {
	const { active, ended } = useMemo(() => {
		if (!device.events) return { active: [], ended: [] }

		const active = []
		const ended = []
		device.events.forEach((event) => {
			if (event.ended) {
				ended.push(event)
			} else {
				active.push(event)
			}
		})
		return { active, ended }
	}, [device.events])

	return (
		<DeviceDetailsBlock className="events-block hide-scrollbar">
			<EventsListBlock
				activeEvents={active}
				endedEvents={ended}
				onDeviceSelect={() => {}} // noop
				selectedDeviceId={0}
				hideId={true}
			/>
		</DeviceDetailsBlock>
	)
}
