import './DeviceChoice.scss'

import React, { useEffect, useState } from 'react'
import Lang from '../../../Lang'
import { Button } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import DevicePicker from '../../../Components/DevicePicker'

export default function DeviceChoice({ deviceIds, onChange }) {
	const [state, setState] = useState(deviceIds)

	function removeDevice(deviceId) {
		setState(state.filter((id) => id !== deviceId))
	}

	useEffect(() => {
		onChange(state)
	}, [state])

	return (
		<div className="device-choice__container">
			<div className="deviceList">
				{deviceIds.map((id) => (
					<div className="device" key={`device-${id}`}>
						<i className="fa-fw fa-duotone fa-router" />
						<div className="title">{id}</div>
						<Button icon="cross" minimal small intent="danger" onClick={() => removeDevice(id)} />
					</div>
				))}
				<div className="buttons">
					<Popover2 content={<DevicePicker selectedDeviceIds={deviceIds} onChange={(newDeviceIds) => setState(newDeviceIds)} />}>
						<Button text={Lang.get('Add Devices')} icon="plus" intent="primary" minimal />
					</Popover2>
				</div>
			</div>
		</div>
	)
}
