import './index.scss'

import Lang from '../../../../../Lang'
import { useMemo, useState } from 'react'
import FilterButton from './Components/FilterButton'
import { DateTime } from 'luxon'
import Accordion from '../../../../../Components/Accordion'

export const EVENT_LABELS = {
	GHOST_ON: Lang.get('Unexpectedly glowing'),
	GHOST_OFF: Lang.get('Unexpectedly dark'),
	INVALID_DIM_LVL: Lang.get('Invalid dim level relative to profile'),
	CONSUMPTION_TOO_HIGH: Lang.get('Consumption too high'),
	CONSUMPTION_TOO_LOW: Lang.get('Consumption too low'),
	DRIVER_FAILURE: Lang.get('Driver failure'),
	LED_FAILURE: Lang.get('LED failure'),
	UNREACHABLE: Lang.get('Unreachable'),
	NO_D4I_REPORTS: Lang.get('No D4i data'),
	NEGATIVE_ACTIVE_POWER: Lang.get('Negative active power')
}

export default function EventsTab({ events, devices, selectDevice, selectedDevice }) {
	const [activeEventTypes, setActiveEventTypes] = useState(Object.keys(EVENT_LABELS))

	const { active, ended } = useMemo(() => {
		if (!events) return { active: [], ended: [] }

		const active = []
		const ended = []
		events.forEach((event) => {
			if (activeEventTypes.includes(event.type)) {
				if (event.ended) {
					ended.push(event)
				} else {
					active.push(event)
				}
			}
		})
		return { active, ended }
	}, [events, activeEventTypes])

	return (
		<div className="events-tab">
			<header>
				<FilterButton value={activeEventTypes} onChange={setActiveEventTypes} />
			</header>

			<EventsListBlock
				activeEvents={active}
				endedEvents={ended}
				onDeviceSelect={(deviceId) => selectDevice(devices.find((d) => d.id === deviceId))}
				selectedDeviceId={selectedDevice?.id}
			/>
		</div>
	)
}

export function EventsListBlock({ activeEvents, endedEvents, onDeviceSelect, selectedDeviceId, hideId = false }) {
	return (
		<>
			<EventsList
				items={activeEvents}
				title={Lang.get('Active events')}
				onDeviceSelect={onDeviceSelect}
				selectedDeviceId={selectedDeviceId}
				defaultCollapsed={false}
				hideId={hideId}
			/>
			<EventsList
				items={endedEvents}
				title={Lang.get('Ended events')}
				onDeviceSelect={onDeviceSelect}
				selectedDeviceId={selectedDeviceId}
				hideId={hideId}
			/>
		</>
	)
}

function EventDescription({ event }) {
	switch (event.type) {
		case 'GHOST_ON':
			return null
		case 'GHOST_OFF':
			return null
		case 'INVALID_DIM_LVL':
			return (
				<div className="description-block">
					<ul>
						<li>
							<strong>Expected (DALI):</strong> {event.data.expected.idle} at idle, {event.data.expected.active} active
						</li>
						<li>
							<strong>Received (DALI):</strong> {event.data.current}
						</li>
					</ul>
				</div>
			)
		case 'CONSUMPTION_TOO_HIGH':
			return (
				<div className="description-block">
					<ul>
						<li>
							<strong>Active power (W):</strong> {event.data.activePower}
						</li>
						<li>
							<strong>Max power (W):</strong> {event.data.maxPower}
						</li>
					</ul>
				</div>
			)
		case 'CONSUMPTION_TOO_LOW':
			return (
				<div className="description-block">
					<ul>
						<li>
							<strong>Active power (W):</strong> {event.data.activePower}
						</li>
					</ul>
				</div>
			)
		case 'DRIVER_FAILURE':
			return (
				<div className="description-block">
					<ul>
						{event.data.failures.map((failure, i) => (
							<li key={`dfli${i}${event.id}`}>
								<strong>{failure}</strong>
							</li>
						))}
					</ul>
				</div>
			)
		case 'LED_FAILURE':
			return (
				<div className="description-block">
					<ul>
						{event.data.failures.map((failure, i) => (
							<li key={`lfli${i}${event.id}`}>
								<strong>{failure}</strong>
							</li>
						))}
					</ul>
				</div>
			)
	}

	return null
}

function EventsList({ items, defaultCollapsed = true, title, onDeviceSelect, selectedDeviceId, hideId = false }) {
	const [isExpanded, setIsExpanded] = useState(!defaultCollapsed)

	// sort items in a way where the ones created most recently are at the top
	items.sort((a, b) => b.created - a.created)

	return (
		<Accordion
			isOpen={isExpanded}
			onOpenChange={setIsExpanded}
			title={`${title} (${items.length})`}
			onHeaderClick={() => setIsExpanded(!isExpanded)}>
			{items.map((event, i) => {
				const created = DateTime.fromSeconds(event.created)
				const updated = event.updated ? DateTime.fromSeconds(event.updated) : null
				const ended = event.ended ? DateTime.fromSeconds(event.ended) : null

				let duration = event.ended ? ended.diff(created) : DateTime.now().diff(created)

				// 14 minutes or 3 hours or 10 days
				if (duration.as('minutes') < 60) {
					duration = duration.toFormat('mm') + ' ' + Lang.get('minutes')
				} else if (duration.as('hours') < 24) {
					duration = duration.toFormat('hh') + ' ' + Lang.get('hours')
				} else {
					duration = duration.toFormat('dd') + ' ' + Lang.get('days')
				}
				if (duration[0] === '0') {
					duration = duration.slice(1)
				}

				const isSelected = event.deviceId === selectedDeviceId

				return (
					<div
						className={`event-item ${isSelected ? 'selected' : ''}`}
						key={`event-${i}`}
						onClick={() => onDeviceSelect(event.deviceId)}>
						<div className="icon">
							<i className="fa-duotone fa-triangle-exclamation" />
						</div>
						<div className="details">
							<div className="deviceId">
								{`${!hideId ? event.deviceId + ' - ' : ''}`}
								<span className="type">{EVENT_LABELS[event.type]}</span>
							</div>

							<div className="from">
								<strong>{Lang.get('From')}:</strong>
								{created.toFormat('dd.LL.yy HH:mm')}
							</div>
							{event.ended ? (
								<div className="to">
									<strong>{Lang.get('To')}:</strong>
									{ended.toFormat('dd.LL.yy HH:mm')}
								</div>
							) : event.updated ? (
								<div className="to">
									<strong>{Lang.get('Updated')}:</strong>
									{updated.toFormat('dd.LL.yy HH:mm')}
								</div>
							) : null}
							<div className="duration">
								<strong>{Lang.get('Duration')}:</strong>
								{duration}
							</div>

							<EventDescription event={event} />
						</div>
					</div>
				)
			})}
		</Accordion>
	)
}
