import { Button, Callout, TextArea } from '@blueprintjs/core'
import { DeviceDetailsBlock } from '../..'
import API, { RFCommand, RFPacket } from '../../../../../../API'
import Time from '../../../../../../Components/Time'
import DeviceCommandViewer from './Components/DeviceCommandViewer'
import React from 'react'

export default function AdminTabPanel({ device }) {
	return (
		<>
			<DeviceDetailsBlock title="Details">
				Received <strong>{device.reportHealth}</strong> reports in last 24h
			</DeviceDetailsBlock>
			<DeviceDetailsGSMBlock device={device} />
			<DeviceDetailsGatewayBlock device={device} />
			<DeviceDetailsBlock title="Last Received Commands">
				<DeviceCommandViewer commands={device.lastCommands} device={device} />
			</DeviceDetailsBlock>
			<DeviceDetailsConfigurationBlock device={device} />
			<DeviceDetailsStreetConfigurationBlock device={device} />
		</>
	)
}

class DeviceDetailsGSMBlock extends React.Component {
	componentDidMount() {
		API.call('agents.list')
		API.addMessageListener(this, 'agent-list', (res) => {
			let foundGateway = null
			for (let con of res.args) {
				if (con.scanReport && con.scanReport.deviceId == this.props.device.id) {
					foundGateway = con
				}
			}
			this.setState({ gateway: foundGateway })
		})
	}

	componentWillUnmount() {
		API.removeListener(this)
	}

	render() {
		const d = this.props.device
		if (!(this.state && this.state.gateway)) {
			return null
		}
		const gw = this.state.gateway
		return (
			<DeviceDetailsBlock title="GSM Connection">
				<table>
					<tbody>
						<tr>
							<td>Connected</td>
							<td className="value">
								<Time iso={gw.timeConnected} />
							</td>
						</tr>
						<tr>
							<td>Address</td>
							<td className="value">{gw.address}</td>
						</tr>

						<tr>
							<td>Firmware</td>
							<td className="value">{gw.scanReport.firmwareVersion}</td>
						</tr>
					</tbody>
				</table>
			</DeviceDetailsBlock>
		)
	}
}

// =============================================================================

class DeviceDetailsGatewayBlock extends React.Component {
	sortGateway(a, b) {
		if (a.lastDataTime > b.lastDataTime) {
			return -1
		} else if (a.lastDataTime < b.lastDataTime) {
			return 1
		}
		if (a.rssi > b.rssi) {
			return -1
		} else if (a.rssi < b.rssi) {
			return 1
		}
		return 0
	}
	render() {
		const d = this.props.device
		return (
			<DeviceDetailsBlock title="Gateways">
				<table>
					<tbody>
						{d.gateways?.sort(this.sortGateway).map((g, idx) => (
							<tr key={`ddgateway-${idx}${g.deviceId}`}>
								<td>{g.deviceId}</td>
								<td>
									<Time timestamp={g.lastDataTime} />
								</td>
								<td>{g.rssi}</td>
							</tr>
						))}
					</tbody>
				</table>
			</DeviceDetailsBlock>
		)
	}
}

// =============================================================================

class DeviceDetailsConfigurationBlock extends React.Component {
	constructor(props) {
		super(props)
		this.state = { busy: false, value: '', error: null }
	}
	async performWithPacket(p) {
		if (this.state.busy) return
		this.setState({ busy: true, error: null })

		const r = await API.sendPacketAndWaitResponse(p, this.props.device, {
			deviceId: this.props.device.id,
			command: RFCommand.CONFIG_REPORT
		})
		if (r) {
			this.setState({ value: JSON.stringify(r.config, null, 2), busy: false })
		} else {
			this.setState({ value: '', busy: false, error: 'Operation Failed!' })
		}
		return r
	}

	async read() {
		const p = new RFPacket()
		p.command = RFCommand.CONFIG_READ
		await this.performWithPacket(p)
	}

	async write() {
		const config = JSON.parse(this.state.value)

		const p = new RFPacket()
		p.command = RFCommand.CONFIG_WRITE
		p.data = Buffer.alloc(11)
		p.data.writeUInt8(config.operationMode, 0)
		p.data.writeUInt8(config.daliSpeed, 1)
		p.data.writeUInt8(config.idleBrightness, 2)
		p.data.writeUInt16LE(config.activeTime, 3)
		p.data.writeUInt8(config.activeBrightness, 5)
		p.data.writeUInt8(config.activeBrightnessN1, 6)
		p.data.writeUInt8(config.activeBrightnessN2, 7)
		p.data.writeUInt8(config.activeBrightnessN3, 8)
		p.data.writeUInt8(config.activeBrightnessN4, 9)
		p.data.writeUInt8(config.activeBrightnessN5, 10)
		await this.performWithPacket(p)
	}

	render() {
		const d = this.props.device
		return (
			<DeviceDetailsBlock title="Configuration">
				<TextArea
					style={{ height: '210px' }}
					disabled={this.state.busy}
					value={this.state.value}
					onChange={(event) => this.setState({ value: event.target.value })}
				/>
				{this.state.error && (
					<Callout icon="error" intent="danger">
						{this.state.error}
					</Callout>
				)}
				<div className="buttons">
					<Button fill text="Read" intent="success" disabled={this.state.busy} onClick={() => this.read()} />
					<Button fill text="Write" intent="danger" disabled={this.state.busy} onClick={() => this.write()} />
				</div>
			</DeviceDetailsBlock>
		)
	}
}

// =============================================================================

class DeviceDetailsStreetConfigurationBlock extends React.Component {
	constructor(props) {
		super(props)
		this.state = { busy: false, value: '', error: null }
	}

	async read() {
		if (this.state.busy) return
		this.setState({ busy: true, error: null })

		const p = new RFPacket()
		p.command = RFCommand.SCAN
		const r = await API.sendPacketAndWaitResponse(p, this.props.device, {
			deviceId: this.props.device.id,
			command: RFCommand.SCAN_REPORT
		})
		if (r) {
			let out = {
				operationMode: r.scanReport.operationMode,
				streetId: r.scanReport.streetId,
				poleId: r.scanReport.poleId
			}
			this.setState({ value: JSON.stringify(out, null, 2), busy: false })
		} else {
			this.setState({ value: null, busy: false, error: 'Operation Failed!' })
		}
	}

	async write() {
		if (this.state.busy) return
		this.setState({ busy: true, error: null })

		const config = JSON.parse(this.state.value)

		const p = new RFPacket()
		p.command = RFCommand.SET_IDENTITY
		p.destinationId = 0 // broadcast
		let buf = Buffer.alloc(13)
		buf.writeUInt32LE(this.props.device.id, 0)
		buf.writeUInt32LE(config.streetId, 4)
		buf.writeUInt32LE(config.poleId, 8)
		buf.writeUInt8(config.operationMode, 12)
		const serialBuf = Buffer.from(this.props.device.serialNr, 'hex')
		buf = Buffer.concat([serialBuf, buf])
		p.data = buf

		this.props.device.broadcast = true
		const r = await API.sendPacketAndWaitResponse(p, this.props.device, {
			deviceId: this.props.device.id,
			command: RFCommand.ACK
		})
		this.props.device.broadcast = false
		if (r) {
			this.setState({ busy: false })
		} else {
			this.setState({ busy: false, error: 'Operation Failed!' })
		}
	}

	render() {
		const d = this.props.device
		return (
			<DeviceDetailsBlock title="Street Configuration">
				<TextArea
					style={{ height: '110px' }}
					disabled={this.state.busy}
					value={this.state.value}
					onChange={(event) => this.setState({ value: event.target.value })}
				/>
				{this.state.error && (
					<Callout icon="error" intent="danger">
						{this.state.error}
					</Callout>
				)}
				<div className="buttons">
					<Button fill text="Read" intent="success" disabled={this.state.busy} onClick={() => this.read()} />
					<Button fill text="Write" intent="danger" disabled={this.state.busy} onClick={() => this.write()} />
				</div>
			</DeviceDetailsBlock>
		)
	}
}
