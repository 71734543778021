import './ReplaceDialog.scss'

import { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Dialog, DialogBody, FormGroup, Radio, RadioGroup } from '@blueprintjs/core'
import API from '../../../API'
import { DateInput3 } from '@blueprintjs/datetime2'
import { DateTime } from 'luxon'
import Lang from '../../../Lang'

const ERROR_MESSAGES = {
	'no-reports': Lang.get('No reports found for new device'),
	'invalid-date': Lang.get('Invalid date'),
	'old-device-not-found': Lang.get('Old device not found'),
	'new-device-not-found': Lang.get('New device not found'),
	'different-account': Lang.get('Devices are not in the same account')
}

export default function ReplaceDialog({ open, setOpen, selectedDevice, onSuccess, devices, reload }) {
	// 1 - select device, type and replace date (if not provided, we will replace ALL stats)
	// after 1 - query server for replace times
	// 2 - select time
	// after 2 - go to phase 3
	// 3 - view replace summary and confirm replace
	// after 3 - query server to replace
	const [phase, setPhase] = useState(1)
	const [newDeviceId, setNewDeviceId] = useState(0)
	const [type, setType] = useState('replace')
	const [wantsReplaceInfo, setWantsReplaceInfo] = useState(false)

	const replaceTimesRef = useRef(null)
	const [selectedReplaceTime, setSelectedReplaceTime] = useState(null)
	const [dateValue, setDateValue] = useState(null)

	const [busy, setBusy] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	async function handleBtnPress() {
		const oldDeviceId = selectedDevice.id

		setBusy(true)

		if (phase == 1) {
			const args = {
				oldDeviceId,
				newDeviceId: parseInt(newDeviceId)
			}

			if (dateValue) {
				args.dateStr = dateValue
			}

			const res = await API.call('Device.GetReplaceDateTimeOptions', args)

			console.log(res)

			if (res.success) {
				replaceTimesRef.current = res.times
				setPhase(args.dateStr ? 2 : 3)
			} else {
				setErrorMessage(res.error)
			}
		}

		if (phase == 2) {
			setPhase(3)
		}

		if (phase == 3) {
			const args = {
				oldDeviceId,
				newDeviceId: parseInt(newDeviceId),
				type,
				wantsReplaceInfo,
				line: selectedReplaceTime ?? 0
			}

			console.log('Device.Replace', args)

			const res = await API.call('Device.Replace', args)
			console.log(res)
			if (res.success) {
				await reload()
				setOpen(false)
				onSuccess()
			}
		}

		setBusy(false)
	}

	devices?.sort((a, b) => a.id - b.id)

	function onClosed() {
		setPhase(1)
		setNewDeviceId(0)
		setDateValue(null)
		setBusy(false)
		setErrorMessage('')
		setWantsReplaceInfo(false)
		setSelectedReplaceTime(null)
	}

	useEffect(() => {
		setErrorMessage('')
	}, [phase])

	return (
		<Dialog isOpen={open} onClose={() => setOpen(false)} onClosed={onClosed} className="replace-dialog">
			<DialogBody className="body">
				<p className="header">
					Register replace for {selectedDevice?.id} {newDeviceId ? ` -> ${newDeviceId}` : ''}{' '}
					{dateValue ? ` on ${DateTime.fromFormat(dateValue, 'yyyy-LL-dd').toFormat('LL/dd/yyyy')}` : ' all time'}
				</p>

				{!!errorMessage && <p className="error">{ERROR_MESSAGES[errorMessage]}</p>}

				{phase == 1 && (
					<>
						<FormGroup label={<strong>Method</strong>} labelFor="new-replace-device" style={{ margin: 0 }}>
							<div className="bp5-html-select" style={{ width: '100%' }}>
								<select style={{ fontSize: 'large' }} onChange={(e) => setType(e.target.value)} value={type}>
									<option value={'replace'}>Replace</option>
									<option value={'swap'}>Swap</option>
								</select>
								<span className="bp5-icon bp5-icon-double-caret-vertical" />
							</div>
						</FormGroup>

						<Checkbox
							checked={wantsReplaceInfo}
							style={{ fontSize: 'large' }}
							label="Replace info"
							onChange={(e) => setWantsReplaceInfo(e.target.checked)}
						/>

						<FormGroup label={<strong>New device</strong>} labelFor="new-replace-device" style={{ margin: 0 }}>
							<div className="bp5-html-select" style={{ width: '100%' }}>
								<select style={{ fontSize: 'large' }} onChange={(e) => setNewDeviceId(e.target.value)} value={newDeviceId}>
									<option value={0}>-</option>
									{devices?.map((device) =>
										device.id === selectedDevice?.id ? null : (
											<option key={`rddi${device.id}`} value={device.id}>
												{device.id}
											</option>
										)
									)}
								</select>
								<span className="bp5-icon bp5-icon-double-caret-vertical" />
							</div>
						</FormGroup>

						<FormGroup label={<strong>Replace date (optional)</strong>} labelFor="new-replace-device" style={{ margin: 0 }}>
							<DateInput3
								formatDate={(d) => DateTime.fromJSDate(d).toFormat('LL/dd/yyyy')}
								parseDate={(str) => DateTime.fromFormat(str, 'LL/dd/yyyy').toJSDate()}
								placeholder="MM/DD/YYYY"
								value={dateValue}
								onChange={setDateValue}
								locale={null}
								dayPickerProps={{ firstDayOfWeek: 1 }}
								highlightCurrentDay={true}
								maxDate={new Date()}
								inputProps={{ style: { fontSize: 'large' } }}
							/>
						</FormGroup>
					</>
				)}

				{phase == 2 && (
					// <FormGroup label={<strong>Replace time</strong>} labelFor="new-replace-device" style={{ margin: 0 }}>
					// 	<div className="bp5-html-select" style={{ width: '100%' }}>
					// 		<select style={{ fontSize: 'large' }}>
					// 			{replaceTimesRef.current?.map((time, idx) => (
					// 				<option key={`rddt${idx}`} value={time.timestamp}>
					// 					{time.time}
					// 				</option>
					// 			))}
					// 		</select>
					// 		<span className="bp5-icon bp5-icon-double-caret-vertical" />
					// 	</div>
					// </FormGroup>
					<RadioGroup
						label={<strong>Choose a replace time</strong>}
						onChange={(e) => setSelectedReplaceTime(parseInt(e.currentTarget.value))}
						selectedValue={selectedReplaceTime}>
						{replaceTimesRef.current?.map((time, idx) => (
							<Radio key={`rddt${idx}`} label={time.time} value={time.timestamp} />
						))}
					</RadioGroup>
				)}

				{phase == 3 && (
					<div>
						<p>
							{selectedDevice?.id} {type} {newDeviceId}
							<strong>{wantsReplaceInfo ? ', info and statistics' : ', statistics only'}</strong>
							{selectedReplaceTime
								? `, at ${DateTime.fromSeconds(selectedReplaceTime).toFormat('LL/dd/yyyy HH:mm')}`
								: ', all time'}
						</p>
					</div>
				)}

				<Button
					className="replace-btn"
					onClick={handleBtnPress}
					disabled={(phase == 1 && newDeviceId == 0) || (phase == 2 && !selectedReplaceTime)}
					loading={busy}
					intent={phase == 3 ? 'success' : 'primary'}>
					{phase == 1 ? 'Next' : 'Replace'}
				</Button>
			</DialogBody>
		</Dialog>
	)
}
