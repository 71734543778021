import './index.scss'

import React, { useEffect, useMemo, useState } from 'react'
import Lang from '../../../../../Lang'
import { getSunriseSunsetTimestamps, pick } from '../../../../../Util'
import ConditionBlock from './ConditionBlock'
import ConditionBlock_new from './ConditionBlock_new'
import { Button, Callout, FormGroup, Slider } from '@blueprintjs/core'
import { DateTime } from 'luxon'

// ===================================================================

export default function ProfileV2Editor({ profile, onChange }) {
	const [daliSpeed, setDaliSpeed] = useState(null)
	const [motionTime, setMotionTime] = useState(null)
	const [conditionBlocks, setConditionBlocks] = useState(null)

	useMemo(() => {
		setDaliSpeed(parseInt(profile.daliSpeed, 10))
		setMotionTime(parseInt(profile.motionTime, 10))
		setConditionBlocks(profile.conditionBlocks)
	}, [profile])

	useEffect(() => {
		onChange({
			daliSpeed,
			motionTime,
			conditionBlocks
		})
	}, [daliSpeed, motionTime, conditionBlocks])

	// console.log('ProfileV2Editor state', {
	// 	daliSpeed,
	// 	motionTime,
	// 	conditionBlocks
	// })

	function conditionBlockAction(action, index, data) {
		setConditionBlocks((cbs) => {
			switch (action) {
				case 'add-block': {
					cbs.splice(index == -1 ? 0 : index + 1, 0, { ...conditionBlockTemplate })
					return [...cbs]
				}

				case 'set-value': {
					cbs[index] = { ...cbs[index], ...data }
					return [...cbs]
				}

				case 'delete': {
					cbs.splice(index, 1)
					return [...cbs]
				}

				case 'duplicate': {
					const cp = { ...cbs[index] }
					cp.brightnessLevels = cp.brightnessLevels.map((p) => ({ ...p }))
					cbs.splice(index, 0, cp)
					return [...cbs]
				}

				case 'move-up': {
					if (index > 0) {
						const tmp = cbs[index]
						cbs[index] = cbs[index - 1]
						cbs[index - 1] = tmp
					}
					return [...cbs]
				}

				case 'move-down': {
					if (index < cbs.length - 1) {
						const tmp = cbs[index]
						cbs[index] = cbs[index + 1]
						cbs[index + 1] = tmp
					}
					return [...cbs]
				}

				default: {
					console.error('Unknown action', action)
				}
			}

			return cbs
		})
	}

	const sunriseSunsetTimes = useMemo(() => {
		const profileCenter = profile.center

		const ret = profileCenter
			? getSunriseSunsetTimestamps(profileCenter.latitude, profileCenter.longitude, new Date())
			: {
					sunrise: window.App.additionalData.defaultSunriseTimestamp,
					sunset: window.App.additionalData.defaultSunsetTimestamp
			  }

		const secondsNow = DateTime.now().startOf('day').toSeconds()

		return {
			sunrise: ret.sunrise - secondsNow,
			sunset: ret.sunset - secondsNow
		}
	}, [profile])

	return (
		<div className="ProfileV2Editor">
			<FormGroup label={Lang.get('Brightness change speed (DALI)')}>
				<Slider max={7} value={daliSpeed} onChange={(val) => setDaliSpeed(val)} />
			</FormGroup>

			<FormGroup label={Lang.get('Motion time')} labelInfo={'(' + Lang.get('seconds') + ')'}>
				<Slider max={600} value={motionTime} onChange={(val) => setMotionTime(val)} labelStepSize={60} />
			</FormGroup>

			<br />
			<br />

			<Callout intent="primary">{Lang.get('The first schedule for which all conditions are true will be activated')}</Callout>

			<br />
			<br />

			<div className="conditionBlocks">
				<Button
					icon="add"
					intent="success"
					minimal
					text={Lang.get('Add Schedule')}
					onClick={() => conditionBlockAction('add-block', -1)}
				/>
				{conditionBlocks?.map((conditionBlock, index) => {
					return (
						<React.Fragment key={`pv2ecb${index}`}>
							<ConditionBlock
								lightingProfileId={profile.id ?? 0}
								blockData={conditionBlock}
								blockAction={(action, data) => conditionBlockAction(action, index, data)}
								sunriseSunsetTimes={sunriseSunsetTimes}
							/>
							<Button
								icon="add"
								intent="success"
								minimal
								text={Lang.get('Add Schedule')}
								onClick={() => conditionBlockAction('add-block', index)}
							/>
						</React.Fragment>
					)
				})}
			</div>

			<br />
			<br />
		</div>
	)
}

// ===================================================================

export const conditionBlockTemplate = {
	active: true,
	comment: '',
	conditions: {},
	brightnessLevels: [
		{
			start: 0,
			duration: 21600,
			idleBrightness: 0.1,
			activeBrightness: 0.5
		},
		{
			start: 21600,
			duration: 21600,
			idleBrightness: 0.2,
			activeBrightness: 0.6
		},
		{
			start: 43200,
			duration: 21600,
			idleBrightness: 0.3,
			activeBrightness: 0.7
		},
		{
			start: 64800,
			duration: 21600,
			idleBrightness: 0.4,
			activeBrightness: 0.8
		}
	]
}

// ===================================================================

export const profileTemplate = {
	conditionBlocks: [
		// { ...conditionBlockTemplate, comment: Lang.get('September 1st'), active: false, conditions: { date: '2021-01-01' } },
		{ ...conditionBlockTemplate, comment: Lang.get('Workdays'), conditions: { weekdays: [1, 2, 3, 4, 5] } },
		{ ...conditionBlockTemplate, comment: Lang.get('Other Days') }
	]
}

// ===================================================================
