import './DeviceList.scss'

import React, { useState } from 'react'
import Lang from '../../../../../../Lang'
import { DateTime } from 'luxon'
import { AdminOnly } from '../../../../../../Util'
import { Tooltip2 } from '@blueprintjs/popover2'
import { Collapse } from '@blueprintjs/core'
import Accordion from '../../../../../../Components/Accordion'
import { DeviceMarker } from '../../../../../../Components/ILMap'

export default function DeviceList(props) {
	const { lightProfiles, devices } = props
	const includedDeviceIds = lightProfiles.flatMap((profile) => profile.deviceIds)
	const excludedDevices = devices.filter((dev) => !includedDeviceIds.includes(dev.id))
	let defaultExpanded = devices.length < 20
	return (
		<div className="DeviceList">
			{lightProfiles.map((profile) => {
				const filteredDevices = devices.filter((dev) => profile.deviceIds.includes(dev.id))
				return (
					<DeviceProfileList
						key={profile.id}
						profile={profile}
						devices={filteredDevices}
						defaultExpanded={defaultExpanded}
						searchingFor={props.searchingFor}
						selectedProfile={props.selectedProfile}
						selectedDevice={props.selectedDevice}
						selectProfile={props.selectProfile}
						selectDevice={props.selectDevice}
					/>
				)
			})}
			{excludedDevices.length > 0 && (
				<DeviceProfileList
					devices={excludedDevices}
					defaultExpanded={defaultExpanded}
					searchingFor={props.searchingFor}
					selectedProfile={props.selectedProfile}
					selectedDevice={props.selectedDevice}
					selectProfile={props.selectProfile}
					selectDevice={props.selectDevice}
				/>
			)}
		</div>
	)
}

function DeviceProfileList(props) {
	const selected = props.selectedProfile == props.profile
	const profile = props.profile || { color: 'gray', title: Lang.get('Other Devices') }

	const onClick = () => props.selectProfile(profile)
	const devices = props.devices.slice().sort((a, b) => a.id - b.id)
	const [isExpanded, setIsExpanded] = useState(props.defaultExpanded)

	let devicesToShow = null
	if (props.searchingFor.length > 0) {
		const s = props.searchingFor
		if (s == '*') {
			devicesToShow = devices
		} else {
			devicesToShow = devices.filter((dev) => {
				return dev.id.toString().startsWith(s)
			})
		}
		if (devicesToShow.length === 0) {
			return null
		}
	} else {
		devicesToShow = devices
	}

	return (
		// <div className="LightProfile">
		// 	<div className={className.join(' ')} onClick={profile.disabled || !profile.id ? null : onClick}>
		// 		<i
		// 			className={'fa-fw expander fa-duotone ' + (isExpanded ? 'fa-chevron-down' : 'fa-chevron-right')}
		// 			onClick={(ev) => {
		// 				ev.stopPropagation()
		// 				setIsExpanded(!isExpanded)
		// 			}}
		// 		/>
		// 		<div className="LightProfile__box--icon" style={{ backgroundColor: profile.color }}>
		// 			<i className="fa-fw fa-solid fa-chart-simple" />
		// 		</div>
		// 		<p className="LightProfile__box--Heading">{profile.title}</p>
		// 		<p className="LightProfile__box--count">({props.devices.length})</p>
		// 	</div>
		// 	<Collapse isOpen={isExpanded} className="hide-scrollbar">
		// 		<div className="DeviceListGroup">
		// 			<div className="content">
		// 				{devicesToShow.map((dev) => (
		// 					<DeviceListItem
		// 						key={dev.id}
		// 						device={dev}
		// 						onClick={() => props.selectDevice(dev, { from: 'list' })}
		// 						selected={props.selectedDevice?.id == dev.id}
		// 					/>
		// 				))}
		// 			</div>
		// 		</div>
		// 	</Collapse>
		// </div>
		<Accordion
			isOpen={isExpanded}
			onOpenChange={setIsExpanded}
			title={`${profile.title} (${props.devices.length})`}
			onHeaderClick={profile.disabled || !profile.id ? null : onClick}
			selected={profile.id && selected}
			disabled={profile.disabled}
			left={
				<div className="lighting-profile-icon-box" style={{ backgroundColor: profile.color }}>
					<i className="fa-fw fa-solid fa-chart-simple" />
				</div>
			}>
			<div className="DeviceListGroup">
				<div className="content">
					{devicesToShow.map((dev) => (
						<DeviceListItem
							key={dev.id}
							device={dev}
							profile={props.profile}
							onClick={() => props.selectDevice(dev, { from: 'list' })}
							selected={props.selectedDevice?.id == dev.id}
						/>
					))}
				</div>
			</div>
		</Accordion>
	)
}

// =============================================================================

class DeviceListItem extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			gateway: null
		}
	}

	componentDidMount() {
		this.checkGSM()
	}

	checkGSM() {
		const { device } = this.props
		let foundGateway = null
		for (const gtw of device.gateways) {
			if (gtw.serialNr === device.serialNr && DateTime.fromSeconds(gtw.lastDataTime).diffNow('days').toObject().days > -7) {
				//console.log(device.id, gtw.lastDataTime, DateTime.fromSeconds(gtw.lastDataTime).diffNow('days').toObject())
				foundGateway = gtw
				break
			}
		}

		if (foundGateway) {
			if (this.state.gateway !== foundGateway) {
				this.setState({ gateway: foundGateway })
			}
		}
	}

	render() {
		const className = ['DeviceListItem']
		if (this.props.selected) {
			className.push('selected')
		}

		const statusIcons = []

		if (!this.props.device.isConfigured) {
			statusIcons.push({
				icon: 'fa-loader',
				title: Lang.get('Installing configuration...'),
				color: 'orange'
			})
		}

		return (
			<div className={className.join(' ')} onClick={this.props.onClick}>
				<div className="icon">
					{/* <i className={'fa-fw fa-duotone fa-' + deviceIcon} /> */}
					<DeviceMarker
						device={this.props.device}
						viewSettings={{
							icons: {
								fillColorType: 'device-status',
								view: {
									idLabel: false
								}
							}
						}}
						isSelected={false}
						onMarkerClick={() => {}}
						onMarkerContextMenu={() => {}}
						hideLightingProfileShadow={true}
					/>
				</div>
				<div className="content">
					<div className="firstLine">
						<span className="deviceId">{this.props.device.id}</span>
						<div className="statusIcons">
							{statusIcons.map((icon, index) => (
								<Tooltip2 content={icon.title} position="bottom" key={`dlisi-${icon.title}${index}`}>
									<i className={`fa-fw fa-duotone ${icon.icon}`} style={{ color: icon.color }} />
								</Tooltip2>
							))}
						</div>
						<span className="name">{this.props.device.title}</span>
						<AdminOnly>
							<span className="info">{this.props.device.info}</span>
						</AdminOnly>
					</div>
				</div>
			</div>
		)
	}
}
