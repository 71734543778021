import { Collapse } from '@blueprintjs/core'
import './Accordion.scss'

export default function Accordion({ children, isOpen, onOpenChange, onHeaderClick, title, left, disabled, selected }) {
	return (
		<div className={`accordion ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}>
			<div onClick={onHeaderClick} className="header">
				<i
					className={'fa-fw fa-duotone fa-chevron-right expander ' + (isOpen ? 'open' : '')}
					onClick={(ev) => {
						ev.stopPropagation()
						onOpenChange(!isOpen)
					}}
				/>
				{left && <div className="left-wrapper">{left}</div>}
				<p className="title">{title}</p>
			</div>
			<Collapse isOpen={isOpen} className="hide-scrollbar" keepChildrenMounted={true}>
				{children}
			</Collapse>
		</div>
	)
}
